<template>
<div class="page list-page">
<div class="page--list">
    <el-form class="private scroll-wrapper pd4 set--bottom-foot-padding"
        :model="PB"
        :rules="rules"
        label-width="150px"
        label-position="right"
        label-suffix=":"
        ref="form"
    >
        <div class="wrapper">
            <el-form-item label="发件人邮箱" prop="sendEmailName">
                <el-input class="width4" v-model.trim="PB.sendEmailName" maxlength="50"></el-input>
            </el-form-item>
            <!-- <el-form-item label="发件人邮箱账号" prop="sendEmailAccount">
                <el-input class="width4" v-model.trim="PB.sendEmailAccount" maxlength="50"></el-input>
            </el-form-item> -->
            <el-form-item label="发件人邮箱密码" prop="sendEmailPsd">
                <el-input class="width4" type="password" v-model.trim="PB.sendEmailPsd" maxlength="50"></el-input>
            </el-form-item>
            <el-form-item label="主题" prop="sendEmailTheme">
                <el-input class="width4" v-model.trim="PB.sendEmailTheme" maxlength="100"></el-input>
            </el-form-item>
            <!-- {{PB.fileList}} -->
            <el-form-item label="收件邮箱" prop="fileList" >
                <!-- cenEmail/saveSendEmail -->
                <!-- :action="$baseURL+'/api/common/fileSingleUpLoad'" -->
                <el-upload
                :data='emaildata'
                :limit='1'
                class="upload-demo"
                :headers='uploadHeaders'
                ref="upload"
                :action="$baseURL+'/api/cenEmail/saveSendEmail'"
                :on-preview="handlePreview"
                :on-remove="handleRemove"
                :file-list="PB.fileList"
                :on-success="handleUploadSuccess"
                :on-change="handleFileChange"
                 :on-exceed="handleExceed"
                :auto-upload="false">
                <el-button slot="trigger" size="small" type="primary">选择文件</el-button>
                 <el-button slot="trigger"  type="text" style="margin-left: 10px;" 
                        @click.stop="downLoadfile"
                    >下载模板</el-button>
                </el-upload>
            </el-form-item>
        </div>
        <!-- {{quillfileList}} -->

        <el-form-item label="邮件内容" class="full-width" prop="content">
            <template>
                <div style="position: relative;" class="width4 quill-editor-wrap" >
                    <el-upload
                        accept='image/*'
                        class="avatar-uploader"
                        :action='picUrl'
                        name="file" 
                        :file-list="quillfileList"
                        :headers="uploadHeaders" 
                        :show-file-list="false"  
                        :on-success="quiiuploadSuccess"  
                        :on-error="quiiuploadError" 
                        :before-upload="beforeUpload"
                        :on-change="quillhandleFileChange"
                    >
                    </el-upload>
                     <!--视频上传弹窗-->
                    <div>
                        <el-dialog :close-on-click-modal="false" width="50%" style="margin-top: 1px" title="视频上传" :visible.sync="videoForm.show" append-to-body>
                            <el-tabs v-model="videoForm.activeName">
                            <el-tab-pane label="添加视频链接" name="first">
                                <el-input v-model="videoForm.videoLink" placeholder="请输入视频链接" clearable></el-input>
                                <el-button type="primary" size="small" style="margin: 20px 0px 0px 0px " @click="insertVideoLink(videoForm.videoLink)">确认
                                </el-button>
                            </el-tab-pane>
                            <el-tab-pane label="本地视频上传" name="second">
                                <el-upload v-loading="loading" style="text-align: center;" drag :action="uploadVideoConfig.uploadUrl" accept="video/*" :name="uploadVideoConfig.name" :before-upload="onBeforeUploadVideo" :on-success="onSuccessVideo" :on-error="onErrorVideo" :multiple="false">
                                <i class="el-icon-upload"></i>
                                <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                                <div class="el-upload__tip" slot="tip">只能上传MP4文件，且不超过{{uploadVideoConfig.maxSize}}M</div>
                                </el-upload>
                            </el-tab-pane>
                            </el-tabs>
                        </el-dialog>
                    </div>
                    <quill-editor ref="myQuillEditor" v-model="PB.content" :options="editorOptions"  @change="handleTextChange($event)">
                    </quill-editor>
                    <span class="wordNumber">{{TiLength}}/100</span>

                </div>
            </template>
        </el-form-item>
        <!-- {{PB.content}} -->
        <div class="foot-bar">
            <el-button :disabled="submitDisabled" @click="handleNavigationBack">取消</el-button>
            <el-button type="primary" :disabled="submitDisabled" @click="handleSubmitButton">发送</el-button>
        </div>
    </el-form>
</div>
</div>
</template>

<script>
import { quillEditor } from 'vue-quill-editor';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css'; // 或者其他主题样式


export default {
    components: {
        quillEditor,
    },
    
        
    name: 'SendEmail',
    // mixins: [forEdit],
    // components: {DepartmentConfig},

    data () {
        const toolbarOptions = [
            ["bold", "italic", "underline", "strike"], // 加粗，斜体，下划线，删除线
            ["blockquote", "code-block"], //引用，代码块
            [{ header: 1 }, { header: 2 }], // 几级标题
            [{ list: "ordered" }, { list: "bullet" }], // 有序列表，无序列表
            [{ script: "sub" }, { script: "super" }], // 下角标，上角标
            [{ indent: "-1" }, { indent: "+1" }], // 缩进
            [{ direction: "rtl" }], // 文字输入方向
            [{ size: ["small", false, "large", "huge"] }], // 字体大小
            [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
            [{ color: [] }, { background: [] }], // 颜色选择
            [
                {
                font: [
                    "SimSun",
                    "SimHei",
                    "Microsoft-YaHei",
                    "KaiTi",
                    "FangSong",
                    "Arial",
                ],
                },
            ], // 字体
            [{ align: [] }], // 居中
            ["clean"], // 清除样式,
            ["link", "image"], // 上传图片、上传视频
            // ['video'], // 视频
        ]

        
        

        const _this = this;
        async function checkSamePhone (rules, val, cb) {
            // console.log(_this);
            const fine = await _this.handlePhoneChange(val);
            if (fine) cb();
            else cb("手机号码重复")
        }
        async function checkSameAccount (rules, val, cb) {
            // console.log(_this);
            const fine = await _this.handleAccontChange(val);
            if (fine) cb();
            else cb("账号重复")
        }
        function pwdValidator (r, value, cb) {
            if (!_this.PB.id) {
                if (!(/^[0-9a-zA-z_]{6,20}$/.test(value)))
                    cb("只能是字母或数字，长度6-20位")
                else cb()
            }
            else {
                if (value.length > 0 && !(/^[0-9a-zA-z_]{6,20}$/.test(value)))
                    cb("只能是字母或数字，长度6-20位")
                else cb()
            }
        }
        function testEmail(r, value, cb){
            if(!(/^[A-Za-z0-9\u4e00-\u9fa5._,]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(value))){ //一般邮箱格式
                cb("请输入正确的邮箱格式")
            }else if(value.length<4){
                cb("请输入正确的邮箱格式")
            }else{
                let  spstr = value.substr(-4);
                console.log(spstr);
                if(spstr != '.com'){ //要求最后必须是.com结尾
                    cb("请输入正确的邮箱格式")
                }else{
                    cb()
                }
            }
        }

        return {
            quillfileList:[],
             loading: false, // 加载loading
            picUrl:this.$baseURL + "/api/common/fileSingleUpLoad",
            quillUpdateImg: null,
            uploading: null,
            TiLength:0,
            editorOptions: {// 编辑器选项配置（可根据需求进行自定义）
                placeholder: '请输入内容', // 设置占位符
                // modules: {
                //     toolbar: [
                //     ['bold', 'italic', 'underline'], // 设置工具栏按钮
                //     [{ header: [1, 2, false] }], // 设置标题样式
                //     ['link', 'image'] // 添加链接和图片插入功能
                //     ]
                // },
                modules: {
                    history: {
                        delay: 1000,
                        maxStack: 50,
                        userOnly: false,
                    },
                    toolbar: {
                        container: toolbarOptions,
                        handlers: {
                            image: function (value) {
                                if (value) {
                                    // 调用element的图片上传组件
                                    document.querySelector(".avatar-uploader input").click();
                                } else {
                                    this.quill.format("image", false);
                                }
                            },
                            // 'video': () => {
                            //     // 覆盖默认的上传视频，点击视频图标，显示弹窗
                            //     _this.videoForm.show = true
                            //         // this.quill.format("video", false);

                            // }
                        },
                    },
                },
            },
               // 视频上传变量
            videoForm: {
                show: false, // 显示插入视频链接弹框的标识
                videoLink: '',
                activeName: 'first'
            },
            // uploadVideoConfig: {
            //     type: Object,
            //     default () {
            //         return {
            //         uploadUrl:this.$baseURL + "/api/common/fileSingleUpLoad",// 上传地址
            //         maxSize: 10, // 图片上传大小限制，默认不超过2M
            //         name: 'Filedata' // 图片上传字段
            //         }
            //     }
            // },
            uploadVideoConfig: {
                uploadUrl:this.$baseURL + "/api/common/fileSingleUpLoad",// 上传地址
                maxSize: 10, // 图片上传大小限制，默认不超过2M
                name: 'Filedata' // 图片上传字段
            },

            maxTextLength:10,
          
            submitDisabled:false,
            uploadHeaders: { // 图片上传的请求头
                token: this.$store.state.token
            },
            fileList: [],
            accept:'',


            // genderEnum, // 性别选项
            // empMap: {}, // 员工信息
            emaildata:{},
            PB: {
                sendEmailName:'',// 发件人邮箱
                // sendEmailAccount:'',// 发件人邮箱账号
                sendEmailPsd:'',// 发件人邮箱密码
                sendEmailTheme:'',// 主题
                files1: [],
                content:'', //邮件内容
                fileList:[]


                

                // id        : undefined,
                // name      : "",
                // phone     : "",
                // account   : "",
                // pwd       : "",
                // enName    : "",
                // sex       : "",
                // age       : "",
                // inDate    : "",
                // email     : "",
                // weixinCode: "",
                // remark1   : "",
                // empSysList : [],  // 用户已经小保存的系统列表数据，大保存时需要发送的
            },
            rules: {
                // sendEmailName: [
                //     { required: true, message: "请输入发件人邮箱" },
                //     { validator (r, value, cb) {
                //         if (!(/^[A-Za-z0-9\u4e00-\u9fa5._]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(value)))
                //             cb("请输入正确的邮箱格式")
                //         else cb()
                //     }},
                // ],
                sendEmailName:[
                    { required: true, message: "请输入发件人邮箱" },
                    { validator: testEmail },
                ] ,
                // sendEmailAccount: [
                //     { required: true, message: "请输入发发件人邮箱账号" },
                //     { validator (r, value, cb) {
                //         if (!(/^[A-Za-z0-9\u4e00-\u9fa5._]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(value)))
                //             cb("请输入正确的邮箱格式")
                //         else cb()
                //     }},
                // ],
                // sendEmailAccount   : { required: true, message: "请输入发件人邮箱账号" },
                sendEmailPsd   : { required: true, message: "请输入发件人邮箱密码" },
                sendEmailTheme  : { required: true, message: "请输入主题" },
                fileList: {required: true, message: "请上传收件邮箱"},
                content: {required: true, message: "请输入邮件内容"},







                // email: [
                //     { required: true, message: "请输入公司邮箱" },
                //     { validator (r, value, cb) {
                //         if (!(/^[A-Za-z0-9\u4e00-\u9fa5._]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(value)))
                //             cb("请输入正确的邮箱格式")
                //         else cb()
                //     }},
                // ],


                // phone  : [
                //     { required: true, message: "请输入电话" },
                //     { validator (r, value, cb) {
                //         if (!(/^\+?\d{5,15}$/.test(value)))
                //             cb("请输入正确的手机号码格式");
                //         else cb();
                //     }},
                //     { validator: checkSamePhone, trigger: "blur" }
                // ],

                // account: [
                //     { required: true, message: "请输入账号" },
                //     { validator (r, value, cb) {
                //         if (!(/^[0-9a-zA-z_]{2,30}$/.test(value))) 
                //             cb("账号只能是数字或字母, 最少2位")
                //         else cb()
                //     }},
                //     { validator: checkSameAccount, trigger: "blur" }
                // ],

                // pwd: { validator: pwdValidator },
                
                // empSysList: {required: true, message: "至少需要添加一个系统"}
            },
            systemList: [],     // 用户界面的系统列表数据，包含已保存和未保存的
            activeSystem: null, // 当前激活的系统tab
        }
      
    },

    created () {
		// this.auth.save = this.$hasAuthFor("api/category/saveCategory") || true;
        if (!this.$route.params.id) this.handleAddSystem();
    },
    

    methods: {
          // 文本编辑
    onEditorChange ({ quill, html, text }) {
      console.log('editor changed:', quill, html, text)
      console.log('html.replace(/<[^>]*>|/g:', html.replace(/<[^>]*>|/g))
      this.$emit('update:content', html)
      this.$emit('change', html)
    },
    hideLoading () {
      this.loading = false
    },
    /** --------- 视频上传相关 start ---------  */
    insertVideoLink (videoLink) {
        console.log(videoLink);

        // 获取富文本组件实例
            let quill = this.$refs.myQuillEditor.quill;
            // 如果上传成功
            if (videoLink) {
                // 获取光标所在位置
                // let length = quill.getSelection().index||0;
                let length = 0;

                // 插入图片，res为服务器返回的图片链接地址
                quill.insertEmbed(length, "video",videoLink);
                // quill.insertEmbed(length, 'video', videoLink)

                // 调整光标到最后
                quill.setSelection(length + 1);
            } else {
                // 提示信息，需引入Message
                this.$message.error("图片插入失败！");
            }
            // this.quillUpdateImg.close();



    //   if (!videoLink) return this.$message.error('视频地址不能为空！')
    //   this.videoForm.show = false
    //   let quill = this.$refs['myQuillEditor'].quill
    //   // 获取富文本
    //   let range = quill.getSelection()
    //   // 获取光标位置：当编辑器中没有输入文本时，这里获取到的 range 为 null
    //   let index = range ? range.index : 0
    //   // 在光标所在位置 插入视频
    //   quill.insertEmbed(index, 'video', videoLink)
    //   // 调整光标到最后
    //   quill.setSelection(index + 1)
    },
    // el-文件上传组件
    onBeforeUploadVideo (file) {
      this.loading = true
      let acceptArr = ['video/mp4']
      const isVideo = acceptArr.includes(file.type)
      const isLt1M = file.size / 1024 / 1024 < this.uploadVideoConfig.maxSize
      if (!isVideo) {
        this.hideLoading()
        this.$message.error('只能上传mp4格式文件!')
      }
      if (!isLt1M) {
        this.hideLoading()
        this.$message.error(`上传文件大小不能超过 ${this.uploadVideoConfig.maxSize}MB!`)
      }
      return isLt1M && isVideo
    },
    // 文件上传成功时的钩子
    onSuccessVideo (res) {

      this.hideLoading()
      if (res.code === '2000') {
        this.insertVideoLink(this.$baseURL+'/'+res.data.files.filePath)
      } else {
        this.$message.error(res.desc)
      }
    },
    // 文件上传失败时的钩子
    onErrorVideo () {
      this.hideLoading()
      this.$message.error('上传失败')
    },
    /**--------- 视频上传相关 end --------- */

        

        handleTextChange(e, html, text) {
            // this.$emit('update:content', html)
            // this.$emit('change', html)

            e.quill.deleteText(100,1);
            if(this.PB.content==''){
                this.TiLength=0
            }else{
                this.TiLength=e.quill.getLength()-1
            }
        },


        // 点击取消
        handleNavigationBack () {
            this.$router.back();
        },
        async validateUpdate () {
             if ([...this.quillfileList].some(({percentage,status}) => (percentage < 100 || status =='uploading'))){
                 this.$message({
                     message: "请等待附件上传完毕",
                     type: "error"
                 });
                 return Promise.reject(false)
             }else{
                return true;
             }

            //  if ([...this.fileList,...this.quillfileList].some(({percentage,status}) => (percentage < 100 || status =='uploading'))){
            //      this.$message({
            //          message: "请等待附件上传完毕",
            //          type: "error"
            //      });
            //      return Promise.reject(false)
            //  }else{
            //     return true;
            //  }
            
         
        },
        // 点击发送
        async handleSubmitButton(){
            this.emaildata.sendEmail = this.PB.sendEmailName
            this.emaildata.emailPass = this.PB.sendEmailPsd
            this.emaildata.title = this.PB.sendEmailTheme
            this.emaildata.emailContent = this.PB.content
            try {
                await this.validateUpdate();
                await this.$refs.form.validate();

                this.uploading = this.$loading();
                this.$refs.upload.submit();

            } catch (reason) {
                reason && this.$message({
                    message: reason.msg || "发送失败",
                    type: "error"
                });
                console.warn(reason);
                // this.uploading.close();

            }
        },

        async handleSubmitButton2 () {
            const loadingRef = this.$loading();

            try {
                await this.validateUpdate();
                await this.$refs.form.validate();

                // const {name,phone,account,pwd,enName,sex,age,inDate,email,weixinCode,remark1,empSysList} = this.PB;
                // const data = {
                //     name,phone,account,enName,sex,age,inDate,email,weixinCode,remark1,
                //     empSysList  : JSON.stringify(empSysList.map(it => ({
                //         system  : it.system,
                //         postId  : it.postId,
                //         locate  : it.locate,
                //         locateId: it.locateId,
                //         manage  : it.manage.map(m => m.code || m).join(),
                //         manageId: it.manage.map(m => m.id || "").join()
                //     }))),
                //     id: this.PB.id
                // }
                // if (pwd) {
                //     console.log("before encrypto", pwd);

                //     const CryptoJS = window.CryptoJS;
                //     // var key       = CryptoJS.enc.Utf8.parse("CENTER001");
                //     var key       = CryptoJS.enc.Utf8.parse("YIMING5AUS900001");
                //     var srcs      = CryptoJS.enc.Utf8.parse(pwd);
                //     var encrypted = CryptoJS.AES.encrypt(srcs, key, {mode:CryptoJS.mode.ECB,padding: CryptoJS.pad.Pkcs7});
                //     data.pwd      = encrypted.toString();

                //     // data.pwd = this.$CryptoJS.AES.encrypt(pwd, "center001").toString()
                // }

                // console.log("提交的数据", data);
                const res = await this.$axios({
                    url: "/api/cenEmail/saveSendEmail",
                    method: "post",
                    data:{
                        title:this.PB.sendEmailTheme,
                        sendEmail:this.PB.sendEmailName,
                        emailPass:this.PB.sendEmailPsd,
                        emailContent:this.PB.content,

                        file:this.PB.files1.join(),

                    }
                })

                if (res.code === 2000) {
                    this.$message({
                        message: "保存成功",
                        type: "success"
                    });
                    loadingRef && loadingRef.close();
                    this.handleNavigationBack();

                } else if (res.code !== 1003) 
                    throw res;


            } catch (reason) {
                // console.log(reason)
                reason && this.$message({
                    message: reason.msg || "保存失败",
                    type: "error"
                });
                console.warn(reason);
                loadingRef && loadingRef.close()
            }
        },
        async handleSubmitButton1 () {
            const loadingRef = this.$loading();

            try {
                await this.$refs.form.validate();
                await this.validateSystems();
                const {name,phone,account,pwd,enName,sex,age,inDate,email,weixinCode,remark1,empSysList} = this.PB;
                const data = {
                    name,phone,account,enName,sex,age,inDate,email,weixinCode,remark1,
                    empSysList  : JSON.stringify(empSysList.map(it => ({
                        system  : it.system,
                        postId  : it.postId,
                        locate  : it.locate,
                        locateId: it.locateId,
                        manage  : it.manage.map(m => m.code || m).join(),
                        manageId: it.manage.map(m => m.id || "").join()
                    }))),
                    id: this.PB.id
                }
                if (pwd) {
                    console.log("before encrypto", pwd);

                    const CryptoJS = window.CryptoJS;
                    // var key       = CryptoJS.enc.Utf8.parse("CENTER001");
                    var key       = CryptoJS.enc.Utf8.parse("YIMING5AUS900001");
                    var srcs      = CryptoJS.enc.Utf8.parse(pwd);
                    var encrypted = CryptoJS.AES.encrypt(srcs, key, {mode:CryptoJS.mode.ECB,padding: CryptoJS.pad.Pkcs7});
                    data.pwd      = encrypted.toString();

                    // data.pwd = this.$CryptoJS.AES.encrypt(pwd, "center001").toString()
                }

                // console.log("提交的数据", data);
                const res = await this.$axios({
                    url: "/api/employee/saveEmp",
                    method: "post",
                    data
                })

                if (res.code === 2000) {
                    this.$message({
                        message: "保存成功",
                        type: "success"
                    });
                    loadingRef && loadingRef.close();
                    this.handleNavigationBack();

                } else if (res.code !== 1003) 
                    throw res;


            } catch (reason) {
                // console.log(reason)
                reason && this.$message({
                    message: reason.msg || "保存失败",
                    type: "error"
                });
                console.warn(reason);
                loadingRef && loadingRef.close()
            }
        },
        submitUpload() {
            if (this.fileList.length == 0) {
                this.$message({
                    message: "请选择上传文件",
                    type: "warning"
                });
            } else {
                // this.uploading = this.$loading();

                // this.uploading = this.$loading({
                //     target: ".circulation-money .el-dialog"
                // });
                console.log(1123);
                this.$refs.upload.submit();
            }
        },
        handleRemove(file, fileList) {
            // console.log(file, fileList);
            this.PB.fileList = fileList
            this.PB.files1 = []
        },
        handlePreview(file) {
            console.log(file);
        },
        handleFileChange(files) {
            this.PB.fileList = [files]
        },
         quillhandleFileChange(files) {
            this.quillfileList = [files]
        },
        beforeUpload(){
            this.quillUpdateImg = this.$loading({
                target: ".quill-editor-wrap "
            });
                // loadingRef && loadingRef.close()

            // .el-dialog
            // this.quillUpdateImg = true;

        },
        quiiuploadError(ee){
             console.log(ee);
            // loading动画消失
            this.quillUpdateImg.close()
            this.$message.error("图片插入失败");

        },
        quiiuploadSuccess(res, file) {
            console.log(res,file);
            // console.log(res)
            // 获取富文本组件实例
            let quill = this.$refs.myQuillEditor.quill;
            // 如果上传成功
            if (res) {
                // 获取光标所在位置
                let length = quill.getSelection().index;
                // 插入图片，res为服务器返回的图片链接地址
                quill.insertEmbed(length, "image",this.$baseURL+'/'+res.data.files.filePath);
                // 调整光标到最后
                quill.setSelection(length + 1);
            } else {
                // 提示信息，需引入Message
                this.$message.error("图片插入失败！");
            }
            this.quillUpdateImg.close();

            // if (res.code == 2001) {
            //     this.$message({
            //         message: res.msg || "文件有误",
            //         type: "warning"
            //     });
            //     setTimeout(() => {
            //         this.$refs.upload.clearFiles();
            //         this.PB.fileList = []
            //     }, 20);
            // }
            // if (res.code == 2000) {
            //     this.$message({
            //         message: '发送成功',
            //         type: "success"
            //     });
            //     setTimeout(() => {
            //       this.handleNavigationBack()
            //     }, 500);
               
            // }

          
        },
        handleUploadSuccess(res, file) {
            this.uploading.close();
            if (res.code == 2001) {
                this.$message({
                    message: res.msg || "文件有误",
                    type: "warning"
                });
                setTimeout(() => {
                    this.$refs.upload.clearFiles();
                    this.PB.fileList = []
                }, 20);
            }
            if (res.code == 2000) {
                this.$message({
                    message: '发送成功',
                    type: "success"
                });
                setTimeout(() => {
                  this.handleNavigationBack()
                }, 500);
                // setTimeout(() => {
                //     if (res.data.returnFile) {
                //         window.open(this.$baseURL + '/' + res.data.returnFile, "download");
                //     }
                // }, 2000);
            }

            // console.log(res);
            // console.log(file);
            // this.PB.files1 = [res.data.files.filePath]


            // this.uploading.close();
            // if (res.code == 2001) {
            //     this.$message({
            //         message: res.msg || "文件有误",
            //         type: "warning"
            //     });
            //     setTimeout(() => {
            //         this.$refs.upload.clearFiles();
            //         this.fileList = []
            //     }, 20);
            // }
            // if (res.code == 2000) {
            //     this.$message({
            //         message: '上传成功，核算结果已自动导出',
            //         type: "success"
            //     });
            //     setTimeout(() => {
            //         this.$refs.upload.clearFiles();
            //         this.fileList = []
            //         this.showCheckAchievementDia = false
            //     }, 500);
            //     setTimeout(() => {
            //         if (res.data.returnFile) {
            //             window.open(this.$baseURL + '/' + res.data.returnFile, "download");
            //         }
            //     }, 2000);
            // }
        },
        handleExceed() {
            this.$message({
                message: `最多只能上传1个文件`,
                type: "warn",
            });
        },
        async downLoadfile() {
            let loadingRef,
                url = '/api/cenEmail/downloadEmailTemplate'
            loadingRef = this.$loading();

            const res = await this.$axios({
                method: "post", url, data: {}
            })
            console.log(res);
            if (res.code === 2000) {
                // this.$message({
                //     message: "下载成功",
                //     type: "success"
                // });
                if (res.data.path) {
                    window.open(this.$baseURL + '/' + res.data.path, "download");
                }
                loadingRef && loadingRef.close();
            } else if (res.code !== 1003)
                throw res;
        },




        // 获取页面数据
        async fetchData() {
			const loading = this.$loading();

			try {
				const res = await this.$axios({
					url: "/api/employee/queryEmpInfo",
					method: "post",
					data: {empId: this.PB.id}
				});

				if (res.code === 2000) {
                    const {empMap} = res.data;
                    this.empMap = empMap;

                    // 把远程的数据回显出来
                    this.PB.name       = empMap.name
                    this.PB.phone      = empMap.phone
                    this.PB.account    = empMap.account
                    this.PB.enName     = empMap.enName  || ""
                    this.PB.sex        = parseInt(empMap.sex) || ""
                    this.PB.age        = empMap.age     || ""
                    this.PB.inDate     = empMap.inDate  || ""
                    this.PB.email      = empMap.email   || ""
                    this.PB.weixinCode = empMap.weixinCode || ""
                    this.PB.remark1    = empMap.remark1 || ""
                    this.PB.empSysList = empMap.empSysList.map(item => ({
                        id               : item.id,
                        system           : item.system,
                        postId           : item.post_id   || "",
                        locate           : item.locate    || "",
                        locateId         : (item.locate && item.locate_id) ? item.locate_id : "",
                        postLevel        : 0,
                        locateLevel      : 0,
                        departmentMixName: item.locatePreName  || "",
                        // manage           : item.manage ? item.manage.split(",") : [],
                        manage           : item.manageList || [],
                        systemName       : item.sysName,
                        saveState        : 1,
                        active           : false,
                    }));
                    this.systemList.push(...this.PB.empSysList)

					loading.close();
				} else if (res.code !== 1003)
					throw res
				
			} catch (reason) {
				console.warn("获取数据失败", reason);
				this.$message({
					message: reason.msg || "获取数据失败",
					type: "warning"
				});

				loading.close();	
			}
        },
        // 手机号验重
        async handlePhoneChange (val) {
            try {
                const res = await this.$axios({
                    url: "/api/employee/querySamePhone",
                    method: "post",
                    data: {
                        phone: val,
                        id   : this.PB.id
                    }
                })

                if (res.code === 2000) {
                    if (res.data.count) {
                        return false
                    } else return true;

                } else if (res.code !== 1003) 
                    throw res;

            } catch (reason) {
                console.warn(reason);
                return false
            }
        },
        // 账号验重
        async handleAccontChange (val) {
            try {
                const res = await this.$axios({
                    url: "/api/employee/querySameAccount",
                    method: "post",
                    data: {
                        account : val,
                        id      : this.PB.id
                    }
                })

                if (res.code === 2000) {
                    if (res.data.count) {
                        return false
                    } else return true;

                } else if (res.code !== 1003) 
                    throw res;

            } catch (reason) {
                console.warn(reason);
                return false
            }
        },

        // 添加一个系统
        handleAddSystem () {
            // const item = createSystemData();
            // this.systemList.push(item);
            // this.handleTabClick(item);
        },

        // 移除一个系统
        async handleSystemRemove (item) {
            if (item.id) return this.jumpForRemove(item);

            try {
                if (item.saveState !== 0) {
                    await this.$confirm("确实删除该系统吗?", "提示")
                }

                const {systemList} = this;
                let index = systemList.indexOf(item);
                this.systemList.splice(index, 1);
    
                index = this.findIndexInRegular(item);
                if (index >= 0) this.PB.empSysList.splice(index, 1);

                if (item == this.activeSystem) this.activeSystem = null;
                this.$message({
                    message: "移除成功",
                    type: "success"
                })
                
            } catch (reason) {
                if (reason != "cancel") {
                    console.warn("移除系统的事件处理出错", reason)
                }
            }
        },
        async jumpForRemove (item) {
            try {
                await this.$confirm(
                    `您是要取消${item.systemName}系统的使用权限么?请去业务系统进行离职操作即可`,
                    '取消系统使用权限', {
                        confirmButtonText: '去离职',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }
                )

                if (item.system === "CEN") return this.handleNavigationBack();
                const sys = this.systemEnum.find(s => s.sysCode === item.system);
                if (!sys.outUrl) return console.log("改配置没有找到匹配的跳转地址", item);

                const loading = this.$loading();
                const res = await this.openSystemURL({
                    url: sys.outUrl,
                    // url: "http://localhost:8030/#/core/staff",
                    system: item.system,
                    params: {
                        empCode: this.empMap.usercode
                    }
                })
                loading.close();
                // if (res !== 2000) throw res;

            } catch (reason) {
                if (reason != "cancel") {
                    console.warn("移除系统的事件处理出错", reason)
                }
            }
        },

        // 查找某个 system 数据在正式数据中的索引 （临时数据和正式数据没
        // 有引用同一个对象，适用JSON.PARSE,JASON.STRINGFY 进行了隔离）
        findIndexInRegular (item) {
            const {empSysList} = this.PB;
            return empSysList.findIndex(it => {
                if (it.id) return it.id === item.id;
                else if (it.nanoid) return it.nanoid === item.nanoid
                else return false
            });
        },

        // 选择系统标签（tab）
        handleTabClick (item) {
            if (item === this.activeSystem) return;
            if (this.activeSystem)
                this.activeSystem.active = false;
            item.active = true;
            this.activeSystem = item;
        },

        // 保存系统（小保存）
        handleSystemSave (manual = false) {
            const {activeSystem: item} = this;

            if (item.saveState === 0) {
                item.saveState = 1;
                this.PB.empSysList.push(JSON.parse(JSON.stringify(item)));

            } else {
                item.saveState = 1;
                const index = this.findIndexInRegular(item);
                this.PB.empSysList.splice(index, 1, JSON.parse(JSON.stringify(item)))
            }

            if (manual) this.$message({
                message: "保存成功",
                type: "success"
            })
        },

        

        async validateSystems () {
            if (this.systemList.some(sys => {
                if (!sys.locateId) {
                    this.activeSystem = sys;
                    const fine = this.$refs.departmentconfig.handleSaveButton();
                    if (!fine) return Promise.reject(false);
                    return true;
                }
                return false;
            })) {
                return Promise.reject(false);
            }
            else return;
        },

        
        handleSuggestInputFocus (e) {
            setTimeout(() => {
                e.target.removeAttribute("readonly")
            }, 1);
        },
        handleSuggestInputBlur (e) {
            e.target.setAttribute("readonly", "readonly")
        },
    }
}
</script>


<style lang='scss' scoped>
    // @import "./staffEdit.scss";
    .wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    max-width: 1000px;
}

.el-form-item {
    // width: 48%;
    width: 90%;

    
    &.full-width {
        width: 100%;
    }
}

.department-config {
    margin-left: 120px;
    max-width: 880px;
}

.handler-button {
    height: 32px;
    line-height: 30px;
    margin-left: 18px;
    padding: 0 12px;
    background: #FFFFFF;
    border-radius: 4px;
    border: 1px solid #2878FF;
    position: relative;
    cursor: pointer;
    white-space: nowrap;
    // overflow-x: hidden;
    // overflow-y: visible;
    text-overflow: ellipsis;
    color: #2878FF;

    &:hover {
        background-color: rgba($color: #2878FF, $alpha: .05);
    }

    .el-button {
        position: absolute;
        right: 0;
        top: 0;
        padding: 0;
        padding-left: 2px;
        background-color: rgba($color: #2878FF, $alpha: .19);
        border-radius: 0 0 0 16px;
        height: 16px;
    }

    .triangle {
        position: absolute;
        left: 0;
        right: 0;
        bottom: -20px;
        width: 0;
        height: 0;
        margin: auto;
        border: 10px solid transparent;
        border-top-width: 0;
        border-bottom-width: 16px;
        border-bottom-color: #ECF0F4;
    }
}

.foot-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0; left: 0; right: 0;
    z-index: 9;
    height: 56px;
    padding-left: 16px;
    padding-right: 16px;
    background-color: white;
    box-shadow: 0px -2px 4px 0px rgba(200, 201, 204, 0.35);
}

/deep/ {
    .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
        width: 100%;
    }
}

/deep/ .ql-container{
    height: 200px;
}
.wordNumber {
    position: absolute;
    right: 10px;
    bottom: 0;
    color: #ccc;
}
/deep/ .ql-editor  img{
    width: 100px;
    height: auto;
}
.avatar-uploader{
    height: 0;
}
</style>